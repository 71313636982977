import { FrInstrumentOfSymbol } from '~/pages/heineken_template/_fr/fr_instrument/_OfSymbol'

/** 為了擴展 `FrInstrumentOfSymbol[]` 開發者語義 */
export class _OfFuturesArray extends Array<FrInstrumentOfSymbol> {
  constructor(args: FrInstrumentOfSymbol[]) {
    super(...args)
  }

  /** 依賴後端 json 要把 `default` 放在第0位元素 */
  get default() {
    return this.at(0)
  }
}
